import React from "react";
import Announcement, { IAnnouncementProps } from "./Announcement";
import HeaderMenuBar from "./HeaderMenuBar";
import HeaderContainerClient from "./HeaderMenuBar/HeaderContainerClient";
import Bubble, { IProps as IBubbleProps } from "./Bubble";
import BottomNotice, { IProps as IBottomNoticeProps } from "./BottomNotice";
import OutSideDomContainer from "./OutSideDomContainer";

export interface IHeaderProps
    extends IAnnouncementProps,
        IBubbleProps,
        Omit<IBottomNoticeProps, "page_name"> {
    hiddenWhenNoFixed?: boolean;
}
const defaultProps = {
    page_name: "user",
};
const Header: React.FC<IHeaderProps> = (prop) => {
    const { page_name, hiddenWhenNoFixed, H5Notice, topNotice, circle } = {
        ...defaultProps,
        ...prop,
    };
    return (
        <>
            {/* header被fixed时考虑动效问题撑开实际高度保护bottomNotice的可见性 */}
            <HeaderContainerClient hiddenWhenNoFixed={hiddenWhenNoFixed}>
                {topNotice && (
                    <Announcement page_name={page_name} topNotice={topNotice} />
                )}
                <HeaderMenuBar hiddenWhenNoFixed={hiddenWhenNoFixed}  />
                <OutSideDomContainer hiddenWhenNoFixed={!!hiddenWhenNoFixed} />
            </HeaderContainerClient>
            {/* 看动效结构，这个是header下面贴内容的常规节点，不参与任何操作，挪出来不影响 */}
            {topNotice && (
                <BottomNotice page_name={page_name} H5Notice={H5Notice} />
            )}
            {/* bubble是fixed, 在不在正确的dom结构里没有意义，挪出来不影响 */}
            {circle && <Bubble page_name={page_name} circle={circle}/>}
        </>
    );
};

export default Header;
