// 俩模块，一个展示，一个操作
import React from "react";
import Client from "./Client";
import { NormalNoticeContent } from "./Server";
import stl from "./styles/notice.module.scss";
import classnames from "classnames";
import CountDownCms from "@/src/components/CountDown/CountDownCms";
import AddGaTopAdClickClient from "../../gtagFunctionComponent/AddGaTopAdClickClient";
import { IStyleProps } from "@/src/app/interface";
import { ICmsProps, INotice } from "./interface";
import FmLink from "@/ui-component/basic/FmLink";
import SwiperCms from "../SwiperCms";
export interface IAnnouncementProps extends INoticeProps {}
export interface INoticeProps extends IStyleProps, ICmsProps {
    topNotice: INotice;
    onScroll?: () => void;
}
const Announcement: React.FC<IAnnouncementProps> = (props) => {
    const { topNotice, page_name } = props;

    const isShowNull =
        !(topNotice.is_show_swiper || topNotice.ad_content) ||
        (topNotice.is_show_swiper && topNotice.ad_list.length < 1);

    const start_time = topNotice.timeRange?.[0]
        ? new Date(topNotice.timeRange[0]).getTime() / 1000
        : 0;

    const expire_time =
        topNotice.timeRange?.[0] && topNotice.timeRange[0]
            ? new Date(topNotice.timeRange[1]).getTime() / 1000
            : 0;
    return (
        <Client {...props}>
            {!isShowNull && topNotice.is_show_swiper ? (
                <SwiperCms
                    ad_list={topNotice.ad_list}
                    isAutoplay={topNotice.is_show_swiper}
                ></SwiperCms>
            ) : (
                <div
                    className={classnames(
                        "default_layout",
                        stl["hidden_swiper"],
                        "hidden_swiper",
                    )}
                >
                    <NormalNoticeContent
                        topNotice={topNotice}
                        page_name={page_name ?? "user"}
                    >
                        <div
                            className={classnames(
                                stl["has_countdown_content"],
                                "has_countdown_content",
                                {
                                    ["hidden"]: !topNotice.has_countdown,
                                },
                            )}
                        >
                            <CountDownCms
                                startTime={start_time}
                                lastTime={expire_time}
                                primary="dark"
                                calligraphy={topNotice.calligraphy}
                                countdownBackgroundColor={
                                    topNotice.countdownBackgroundColor
                                }
                            />
                        </div>
                        <AddGaTopAdClickClient
                            className={classnames(
                                stl["GoShop_btn"],
                                "GoShop_btn",
                                {
                                    ["hidden"]: !topNotice.has_skip_btn,
                                },
                            )}
                        >
                            <FmLink
                                className={stl["link"]}
                                href={topNotice.skip_link}
                                style={{
                                    color: topNotice.skip_backgroundColor,
                                }}
                            >
                                {topNotice.skip_content}
                            </FmLink>
                        </AddGaTopAdClickClient>
                    </NormalNoticeContent>
                </div>
            )}
        </Client>
    );
};

export default Announcement;
